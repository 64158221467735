<template>
  <div :class="$style.listWrap">
    <ScreenSelect :showType="false" @changeTimeRange="selectTime" />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.lastUpdateTime"
      :loading="loading"
      @change="handlePagination"
      :scroll="{ x: '100%', y: 'max-content' }"
      :transformCellText="({ text }) => (text === -1 ? '-' : text || '-')"
    >
      <template slot="weight" slot-scope="text, record">
        <div>
          <span style="margin-right: 5px">{{ record.weight }}</span>
          <a-icon
            v-if="record.overWeightStatus !== '-'"
            :class="showIcon(record.overWeightStatus).className"
            :type="showIcon(record.overWeightStatus).icon"
            theme="filled"
          />
        </div>
      </template>
      <!-- <template slot="tiltXAngle" slot-scope="text, record">
        <div>
          <span style="margin-right: 5px">{{ record.tiltXAngle }}</span>
          <a-icon
            v-if="record.obliqueXStatus !== '-'"
            :class="showIcon(record.obliqueXStatus).className"
            :type="showIcon(record.obliqueXStatus).icon"
            theme="filled"
          />
        </div>
      </template>
      <template slot="tiltYAngle" slot-scope="text, record">
        <div>
          <span style="margin-right: 5px">{{ record.tiltYAngle }}</span>
          <a-icon
            v-if="record.obliqueYStatus !== '-'"
            :class="showIcon(record.obliqueYStatus).className"
            :type="showIcon(record.obliqueYStatus).icon"
            theme="filled"
          />
        </div>
      </template> -->
    </a-table>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import { deviceInfo } from '@/services/device-manage/device-detail';
import ScreenSelect from '@/views/safety-device/component/screen-select';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class DeviceStatus extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
    }
  }
  mounted() {
    this.getTableList();
  }

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.reportTime'),
        dataIndex: 'lastUpdateTime',
        customRender: txt => this.formateTime(txt),
        width: 180,
      },
      {
        align: 'left',
        title: '料台重量(kg)',
        dataIndex: 'weight',
        scopedSlots: { customRender: 'weight' },
        width: 120,
      },
      {
        align: 'left',
        title: '高度(m)',
        dataIndex: 'height',
        width: 100,
      },
      {
        align: 'left',
        title: '速度(m/s)',
        dataIndex: 'speed',
        width: 100,
      },
      {
        align: 'left',
        title: '倾角角度(°)',
        dataIndex: 'dip',
        width: 120,
      },
      {
        align: 'left',
        title: '重量状态',
        dataIndex: 'weightStatus',
        width: 100,
        customRender: text => ['-', '正常', '预警', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '速度状态',
        dataIndex: 'speedStatus',
        width: 100,
        customRender: text => ['-', '正常', '预警', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '倾角角度状态',
        dataIndex: 'dipStatus',
        width: 150,
        customRender: text => ['-', '正常', '预警', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '最大载重(kg)',
        dataIndex: 'maxWeight',
        width: 120,
      },
      // {
      //   align: 'left',
      //   title: this.$t('detail.angleX'),
      //   dataIndex: 'tiltXAngle',
      //   scopedSlots: { customRender: 'tiltXAngle' },
      //   width: 100,
      // },
      // {
      //   align: 'left',
      //   title: this.$t('detail.angleY'),
      //   dataIndex: 'tiltYAngle',
      //   scopedSlots: { customRender: 'tiltYAngle' },
      //   width: 100,
      // },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  showIcon(v) {
    return {
      icon: ['check-circle', 'exclamation-circle', 'exclamation-circle'][v],
      className: [this.$style.normal, this.$style.warning, this.$style.alarm][
        v
      ],
    };
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    try {
      this.loading = true;
      const { records, current, size, total } = await deviceInfo(
        params,
        this.deviceGroup,
      );
      this.tableData = records.map(v => ({
        ...v,
        lastUpdateTime: v.lastUpdateTime,
        // weight: v.weight !== -1 ? `${v.weight}吨` : '-',
        // tiltXAngle: v.tiltXAngle + '度',
        // tiltYAngle: v.tiltYAngle + '度',
      }));
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style lang="less" module>
.listWrap {
  .normal {
    color: var(--success);
  }
  .warning {
    color: var(--warning);
  }
  .alarm {
    color: var(--delete);
  }
}
</style>
